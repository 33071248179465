.NavBar {
  display: flex;
  flex-direction: row;
  background-color: #ff914d;
  width: 100%;

  .logo {
    padding-top: 8px;
    padding-right: 5px;
    padding-left: 30px;
    display: flex;
    height: 100px;
    width: 150px;
  }

  .NavBarList {
    justify-content: flex-end;
    display: flex;
    padding: 10px;
    margin-right: 40px;
    align-items: center;
    min-width: fit-content;
    width: 100%;

    li {
      display: block;
      flex: 0 1 auto; /* Default */
      list-style-type: none;
      cursor: pointer;
      padding: 10px;
      color: white;
      font-size: 1.2em;
      font-weight: bold;
    }

    .navActive {
      border-bottom: 3px solid white;
      transition: border-bottom 0.5s ease-in-out;
    }

    .navItem:link,
    .navItem:visited {
      color: white;
      text-align: center;
      text-decoration: none;
      display: flex-block;
    }
  }
}
