.Home {
  display: flex;
  flex-direction: column;
  //padding: 30px;
  text-align: center;

  h1 {
    font-family: Arial, Helvetica, sans-serif;
    margin: 7px 7px;
    text-decoration: underline;
  }
  h2 {
    margin: 4px;
    margin-top: 5px;
  }
  h3 {
    margin: 4px;
  }
  p {
    margin: 4px;
    margin-bottom: 10px;
  }
  img {
    width: 100%;
    //max-width: 700px;
    //border-radius: 30px;
    height: auto;
    //padding: 10px;
  }
}

.who-we {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  height: 175px;
  max-width: 750px;
  margin-top: 3ch;
  border: 3px solid rgb(250, 139, 98);
}

.contact-block {
  justify-content: left;
}

.subscribe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 175px;
  margin-top: 5ch;
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff;
  //border: 3px solid cadetblue;
}

.subscribe-btn {
  padding: 10px 20px;
  cursor: pointer;
  width: 20%;
  font-size: 1rem;
  border: none;

  background-color: coral;
  color: #fff;
}

.subscribe-btn:hover {
  opacity: 0.8;
}
