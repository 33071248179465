* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*
body{
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.7;
  background: #eee;
  color: #333;
}*/

.form-group,
.form-group label,
.form-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.form-group input:focus {
  border-bottom: 3px solid coral;
}

.btn {
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
}

.btn:hover {
  opacity: 0.8;
}

.btn-primary {
  background-color: coral;
  color: #fff;
}

.error-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: green;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}

.success-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: green;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}
