.create-screen {
  width: 100%;
  height: auto;
  margin: 7ch 0ch;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: visible;
}

.create-screen__form {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff;
  text-align: center;
  align-items: center;
}

.create-screen__title {
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

.create-form-group {
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff;
}
.create-form-group,
.create-form-group label,
.create-form-group input {
  width: 50%;
  margin-bottom: 0.5rem;
}

.create-form-group input,
.description input,
.source-block input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.description,
.description input {
  width: 100%;
}
.create-form-group input:focus {
  border-bottom: 3px solid coral;
}

/*--------------------- Date and Casualties Block --------------------------*/
.date,
.casualties {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.date label,
.date input,
.casualties input,
.casualties label {
  width: 50%;
  margin-bottom: 0.5rem;
}

.date input .casualties input {
  width: 70%;
  padding: 10px 10px;
  margin: 20px, 10px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.block-container {
  width: 50%;
}

/*--------------------- Type Block --------------------------*/
.type {
  margin-top: 3ch;
  width: auto;
  display: flex;
}

/*--------------------- District Block --------------------------*/

.district {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
}
.district input {
  width: 33%;
  padding: 10px 10px;
  margin: 20px, 10px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

/*--------------------- Location Block --------------------------*/

.location {
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  padding: 1ch;
}

.location label,
.location input {
  width: 50%;
  margin-bottom: 0.5rem;
}

.location input {
  width: 80%;
  padding: 10px 10px;
  margin: 20px, 10px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

/*--------------------- Source Block --------------------------*/
.source {
  width: auto;
  display: flex;
  flex-direction: row;
}
.source input {
  width: 50%;
  margin: 10px;
}
